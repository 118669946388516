<template>
  <div class="WebComponentSync">
    <h1 class="WebComponentSync-title">WebComponentSync</h1>
    <p>This content is loaded synchronous, no API requests whatsoever</p>
  </div>
</template>

<script>
export default {
  name: "WebComponentSync"
};
</script>

<style lang="scss">
.WebComponentSync {
  $block: &;

  &-title {
    font-size: 20px;
  }
}
</style>
