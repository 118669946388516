<template>
  <div class="App">
    <h1>Web Components SEO Tests</h1>
    <WebComponentSync class="App-item" />
    <WebComponentAsync class="App-item" :loadingTime="2.5" />
    <WebComponentAsync class="App-item" :loadingTime="5" />
    <WebComponentAsync class="App-item" :loadingTime="10" />
    <WebComponentAsync class="App-item" :loadingTime="15" />
    <WebComponentAsync class="App-item" :loadingTime="20" />
    <WebComponentAsync class="App-item" :loadingTime="25" />
    <WebComponentAsync class="App-item" :loadingTime="30" />
  </div>
</template>

<script>
import WebComponentSync from "@/components/WebComponentSync.vue";
import WebComponentAsync from "@/components/WebComponentAsync.vue";

export default {
  name: "App",
  components: {
    WebComponentSync,
    WebComponentAsync
  }
};
</script>

<style lang="scss">
.App {
  $block: &;

  font-family: sans-serif;


  &-item {
    margin-bottom: 30px;
  }
}
</style>
