<template>
  <div class="WebComponentAsync">
    <h1 class="WebComponentAsync-title">
      WebComponentAsync — {{ loadingTime }}s
    </h1>

    <template v-if="isLoading"> Loading… </template>

    <template v-if="!isLoading">
      <p>This content is loaded after {{ loadingTime }} seconds</p>
    </template>
  </div>
</template>

<script>
export default {
  name: "WebComponentAsync",
  components: {},

  props: {
    loadingTime: { type: Number, default: undefined }
  },

  data() {
    return {
      isLoading: true
    };
  },

  mounted() {
    this.loadContent();
  },

  methods: {
    async loadContent() {
      await new Promise(resolve =>
        setTimeout(resolve, this.loadingTime * 1000)
      );
      this.isLoading = false;
    }
  }
};
</script>

<style lang="scss">
.WebComponentAsync {
  $block: &;

  &-title {
    font-size: 20px;
  }
}
</style>
